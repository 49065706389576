<!-- 绑定手机号 -->
<template>
    <div>
        绑定手机号
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    components: {},

    mounted() { },

    methods: {}
}

</script>
<style scoped></style>